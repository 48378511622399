import { PreLoader } from '@/components';
import RegisterController from '@/fake_shuttle/components/containers/RegisterController';
import { FC, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const Register: FC = () => {
  const navigate = useNavigate();
  const { slug } = useParams();
  const nextPageURL = '/addresses';

  useEffect(() => {
    if (slug === undefined) {
      return navigate(nextPageURL);
    }
  }, [navigate, slug]);

  return slug ? <RegisterController slug={slug} /> : <PreLoader showText />;
};

export default Register;
